
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import ActivitiesOverviewJobs from '@/components/data-operations/activities-overview/jobs/CollectionListing.vue';
import CollectionMixin from '@/mixins/data-operations/collection/collection-mixin';

@Component({
	components: { ActivitiesOverviewJobs },
})
export default class ActivitiesOverviewRunsListingView extends Mixins(HeaderInfosMixin, CollectionMixin) {}
