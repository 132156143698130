
import { Component, Mixins } from 'vue-property-decorator';
import { AnyObject, HeaderItem, ListingComponentProps } from '@/types';

import ActivitiesJobsConfsMixin, {
	NB_EXECUTION,
	NB_SUCCESS,
	NB_FAILED,
} from '@/mixins/data-operations/collection/activities-jobs-confs-mixin';
import RunCollectionMixin from '@/mixins/data-operations/collection/run-collection-mixin';
import ListingComponent from '@/components/data-operations/common/listing/ListingComponent.vue';

import { Statuses } from '@/constants/data-operations/filters';
import {
	CONFIGURATION_ID,
	CONFIGURATION_TYPE,
	DURATION,
	ENVIRONMENT,
	EXECUTION_DATE_UTC,
	STATUS,
	TRIGGER,
} from '@/constants/data-operations/listing/header-items';
import { ACTIVITIES_OVERVIEW_RUNS_LISTING } from '@/constants/router/routes-names';

@Component({
	components: { ListingComponent },
})
export default class CollectionListing extends Mixins(ActivitiesJobsConfsMixin, RunCollectionMixin) {
	getExecutionsLink({ configuration_id, configuration_type, job_id }: AnyObject) {
		return {
			name: ACTIVITIES_OVERVIEW_RUNS_LISTING,
			query: { search: this.isJobIdNull(job_id) ? configuration_id : job_id, configuration_type },
		};
	}

	// TODO: Make common
	getTriggerValue(jobId: string) {
		const splitTrigger = jobId.split('|');
		return splitTrigger[splitTrigger.length - 1];
	}

	get listingComponentProps(): ListingComponentProps {
		let headers: HeaderItem[] = [];
		let overriddenColumns: string[] = [];

		headers = [
			ENVIRONMENT,
			CONFIGURATION_TYPE,
			CONFIGURATION_ID,
			{ text: 'Job ID', value: 'job_id', align: ' d-none' },
			{
				...TRIGGER,
				value: 'trigger',
			},
			NB_EXECUTION,
			{
				...STATUS,
				text: 'Last Status',
			},
			{
				...EXECUTION_DATE_UTC,
				text: 'Last Execution',
			},
			{
				...DURATION,
				text: 'Last Duration',
			},
			NB_SUCCESS,
			NB_FAILED,
		];
		overriddenColumns = ['configuration_id', 'executions', 'execution_date_utc', 'nb_success', 'nb_failed'];

		const customDataFetching = async () => {
			const activitiesRuns = await this.getTailerActivitiesRuns();
			return Object.values(
				activitiesRuns.reduce((accumulator, currentValue) => {
					const id = `${currentValue.environment}-${currentValue.configuration_type}-${currentValue.configuration_id}-${currentValue.job_id}`;
					accumulator[id] = [...(accumulator[id] || []), currentValue];
					return accumulator;
				}, {})
			).map((activitiesRunsGroup) => {
				let nb_success = 0;
				let nb_failed = 0;
				const lastRun = activitiesRunsGroup[0];

				for (let i = 0; i <= activitiesRunsGroup.length - 1; i++) {
					const { status } = activitiesRunsGroup[i];
					if (status === Statuses.Success) nb_success++;
					if (status === Statuses.Failed) nb_failed++;
				}

				return {
					id: lastRun.document_id,
					environment: lastRun.environment,
					configuration_type: lastRun.configuration_type,
					configuration_id: lastRun.configuration_id,
					job_id: lastRun.job_id,
					trigger: this.getTriggerValue(lastRun.job_id),
					executions: activitiesRunsGroup.length,
					status: lastRun.status,
					execution_date_utc: lastRun.execution_date_utc,
					duration: this.getFormattedDuration(lastRun.duration),
					nb_success,
					nb_failed,
				};
			});
		};

		return {
			type: this.listingType,
			moduleName: 'tailer-activities-runs',
			headers,
			overriddenColumns,
			customDataFetching,
			customFiltersValues: [this.configurationType],
		};
	}
}
